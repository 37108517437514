import React, { useEffect, useState } from 'react'
import './Google2FA.css'
import Logo from "./../../Images/logo.png"
import Google from "./../../Images/logo.png"
import { toast } from 'react-toastify';
import QR from './../../Images/qr.png';
import { ApiPaths } from '../../Config'
import axios from 'axios'
import { Data } from './../../Common/Data'
import Loader from './../../Components/Loader/Loader';
const Google2FA = () => {
    const [loading, setLoading] = useState(false);
    const [qrData, setQrData] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const token = localStorage.getItem('token')
    useEffect(() => {
        fetchData();
    }, [])

    const toastSuccess = (msg) => toast.success(msg);
    const toastFailed = (msg) => toast.error(msg);

    function fetchData() {
        setLoading(true);
        let userId = localStorage.getItem('userId');
        axios({
            method: "post",
            url: ApiPaths.fetchQr,

            headers: {
                "Content-Type": "multipart/form-data",
                token: token
            },
        })
            .then(function (response) {
                Data.isDebug && console.log(response);

                setQrData(response?.data)
                setLoading(false);
            })
            .catch(function (response) {
                Data.isDebug && console.log(response);
                setLoading(false);
            });
    }

    function veifyCode() {
        setLoading(true);
        axios({
            method: "post",
            url: ApiPaths.verifyAuthCode,
            data: {
                token_data: authCode
            },
            headers: {
                "Content-Type": "multipart/form-data",
                token: token
            },
        })
            .then(function (response) {
                Data.isDebug && console.log(response);
                if (response?.data?.res == "success") {
                    toastSuccess(response?.data?.res);
                    fetchData();
                } else {
                    alert(response?.data?.res?.replace(/<\/?p>/g, ''));
                    toastFailed(response?.data?.res?.replace(/<\/?p>/g, ''));
                }
                setLoading(false);
            })
            .catch(function (response) {
                Data.isDebug && console.log(response);
                setLoading(false);
            });
    }


    return (
        <div>
            {
                loading ? <Loader /> : null
            }
            {qrData?.verify_status !== 0 ? (
                <section className='googleAuth inputPrimary dashboard'>
                    <div className='googleAuthDiv'>
                        <img id='authLogo' src={Logo} alt="logo.png" />
                        <h1>Set Up Two-Factor Authentication</h1>
                        <p>Download an authenticator app</p>
                        <p><span>Android,IOS and Blackberry</span> - Google Authenticator</p>
                        <p><span>Windows Phone</span> - Authenticator</p>
                        <p>Scan this code with the app</p>
                        <img draggable={false} id='authQR' src={qrData?.qr_code_url} alt="qr.png" />
                        {/* <img draggable={false} id='authQR' src={QR} alt="qr.png" /> */}
                        <h5>{qrData?.key}</h5>
                        <p className='m-0'>Enter the 6-digit code generated by the app</p>
                        <label>Code</label>
                        <input type="text" placeholder='Code' value={authCode} onChange={(e) => setAuthCode(e.target.value)} />
                        <button className='btnPrimary mt-3' onClick={veifyCode}>VERIFY CODE</button>
                    </div>
                </section>
            ) : (
                <div className="dashboard">
                    <div className='googleEnable'>
                        <img id='google_id' src={Google} alt="google.png" />
                        <h4> Google Authentication Enable </h4>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Google2FA