import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Slot.css";
import { Row, Col } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";
import Wallet from "./../../Images/wallet.png";
import Team from "./../../Images/team.png";
import { ApiPaths } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import InfoPage from "../../Components/InfoPage/InfoPage";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Timer from "../../Components/Timer";
import { Data } from "../../Common/Data";
import Leaf from "./../../Images/leafs.png";
import clubIncome from "./../../Images/ClubIncome.svg";
import DirectReferralIncome from "./../../Images/DirectReferralIncome.svg";
import PairMatchingIncome from "./../../Images/PairMatchingIncome.svg";
import FastMovingIncome from "./../../Images/FastMovingIncome.svg";
import FounderClubIncome from "./../../Images/FounderClubIncome.svg";
import LevelIncome from "./../../Images/LevelIncome.svg";
import RankRewards from "./../../Images/RankRewards.svg";
import ReturnOnInvestment from "./../../Images/ReturnOnInvestment.svg";
import MyChart from "../../Components/MyChart/MyChart";
import ProgressBars from "../RankProgressBar/ProgressBars";
import ConnectButton from "../../Components/ConnectButton";
import { RxLapTimer } from "react-icons/rx";
// import Plans from "../Plans/Plans";
import StakedCoin from "../../Common/TotalStaking";
import CoinLiveRate from "../../Common/CoinLiveRate";
import BoosterProgressBars from "../BoosterProgressBar/BoosterProgressBar";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [myRank, setMyRank] = useState();
  const [incomeInfo, setIncomeInfo] = useState(false);
  const [incomeHeading, setIncomeHeading] = useState(false);
  const [infoData, setInfoData] = useState();
  const [planData, setplanData] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [stakedCoinRate, setStakedCoinRate] = useState();
  const [coinLiveRate, setCoinLiveRate] = useState();

  const directs = {
    required: dashboardData?.rank?.next_rank?.dirReq ?? "0",
    achieved: dashboardData?.rank?.next_rank?.dirAch ?? "0",
  };
  const ranks = {
    required: dashboardData?.rank?.next_rank?.rankDirReq ?? "0",
    achieved: dashboardData?.rank?.next_rank?.rankDirAch ?? "0",
  };
  const team = {
    required: dashboardData?.rank?.next_rank?.teamReq ?? "0",
    achieved: dashboardData?.rank?.next_rank?.teamAch ?? "0",
  };
  const boosterDirect = {
    required: dashboardData?.booster?.next_booster?.boosterDirReq ?? "0",
    achieved: dashboardData?.booster?.next_booster?.boosterDirAch ?? "0",
  };
  const boosterBusiness = {
    required: dashboardData?.booster?.next_booster?.boosterBusReq ?? "0",
    achieved: dashboardData?.booster?.next_booster?.boosterBusACh ?? "0",
  };

  let myArray = Array.from({ length: 4 });
  let iconArray = [
    clubIncome,
    ReturnOnInvestment,
    DirectReferralIncome,
    LevelIncome,
    PairMatchingIncome,
    FastMovingIncome,
    FounderClubIncome,
    RankRewards,
  ];
  let x = 0;
  useEffect(() => {
    if (x === 0) {
      getStakedCoinRate();
      getCoinRate();

      checkData();
      FetchPlanData();
      x = 1;
    }
  }, []);
  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);
  function IncomeInfoState(newstate) {
    setIncomeInfo(newstate);
  }
  async function getStakedCoinRate() {
    let stakeRate = await StakedCoin();
    console.log(stakeRate);
    setStakedCoinRate(stakeRate);
  }
  async function getCoinRate() {
    let rate = await CoinLiveRate();
    console.log(rate);
    setCoinLiveRate(rate);
  }
  function InfoPageData(heading, data) {
    Data.isDebug && console.log(heading);
    setIncomeHeading(heading);
    setInfoData(data);
    setIncomeInfo(true);
  }
  function FetchPlanData() {
    setLoading(true);
    axios({
      method: "get",
      url: ApiPaths.getPlans,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("plan data", response);
        let tempPlan = response?.data?.plan_name;
        let effective_date = response?.data?.effectiveDate;
        console.log("effectiveDate", effective_date);
        console.log("tempPlan", tempPlan);
        if (tempPlan.length > 0) {
          setButtonStatus(false);
        } else {
          setButtonStatus(true);
        }
        setplanData(response?.data);
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });
  }
  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    Data.isDebug && console.log("data", data);
    if (data) {
      setDashboardData(data);
      setMyRank(data?.profile?.[0]?.my_rank);
      FetchData();
    } else {
      FetchData(true);
    }
  }

  function FetchData(checkload) {
    if (checkload) {
      setLoading(true);
    }
    let userId = localStorage.getItem("userId");
    Data.isDebug && console.log("user id", userId);
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("..............", response);
        setDashboardData(response?.data);
        console.log(
          "firsttrru",
          response?.data?.booster?.next_booster?.boosterDirReq
        );
        Data.isDebug &&
          console.log("first", response?.data?.profile?.[0]?.my_rank);
        setMyRank(response?.data?.profile?.[0]?.my_rank);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        setLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }
  function metaRequest() {
    setSubmitLoading(true);
    let userId = localStorage.getItem("userId");
    axios({
      method: "post",
      url: ApiPaths.metaRequest,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        Data.isDebug && console.log(response);
        if (response?.data?.res == "success") {
          toastSuccess(response?.data?.message);
        } else {
          toastFailed(response?.data?.message);
        }
        setSubmitLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setSubmitLoading(false);
      });
  }

  return (
    <>
      {loading ? <Loader /> : null}
      {incomeInfo ? (
        <InfoPage
          updateState={IncomeInfoState}
          heading={incomeHeading}
          data={infoData}
        />
      ) : null}
      <ToastContainer />
      <section className="dashboard">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="liveRateDiv">
            <h5>Live Rate</h5>
            <div>
              <p>CIPHERX : </p>
              <p>$ {parseFloat(dashboardData?.token_rate).toFixed(4)}</p>
            </div>
          </div>
          <div className="liveRateDiv">
            <ConnectButton />
          </div>
        </div>
        <section className="cappingSection mt-4">
          <div style={{ margin: "20px 0px" }} className="viewCappingDiv ">
            <h1 className="textHeading">Daily Roi</h1>
            <Link to="staking_details" className="mb-2">
              <p>View All</p>
            </Link>
          </div>
          <div>
            <Row>
              <Col md="6" className="mb">
                <div className="planDivItems">
                  <div style={{ justifyContent: "space-between" }}>
                    <p style={{ color: "white" }}>Staked Token</p>
                    <h4 style={{ color: "var(--colorPrimary)" }}>
                      {dashboardData?.subcription_stake ?? "0"}
                    </h4>
                  </div>

                  <div>
                    <i>
                      <RxLapTimer />
                    </i>
                    <h1>
                      {planData?.effectiveDate && (
                        <Timer time={planData?.effectiveDate} />
                      )}
                    </h1>
                  </div>
                </div>
              </Col>
              <Col md="6" className="mb">
                <div className="planDivItems">
                  <p>Total Staking</p>
                  <h4 style={{ color: "var(--colorPrimary)" }}>
                    {dashboardData?.repurchase_token_sum ?? "0"}
                  </h4>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <h1 style={{ margin: "20px 0px" }} className="textHeading">
          Dashboard
        </h1>
        <Row>
          <Col lg="12">
            <Row style={{ height: "100%" }}>
              <Col md="4" className="mb">
                <div className="dashboardMainAccountCard d-flex flex-column justify-content-between">
                  {dashboardData != null && dashboardData ? (
                    <MyChart className="dashboardChart" data={dashboardData} />
                  ) : (
                    ""
                  )}

                  <div className="metaDiv"></div>
                  <div>
                    <div className="d-flex gap-2">
                      <Link
                        to="plans"
                        className="flex-1"
                        style={{ width: "100%" }}
                      >
                        <button className="btnPrimary">Investment</button>
                      </Link>
                      <Link
                        to="withdraw"
                        className="flex-1"
                        style={{ width: "100%" }}
                      >
                        <button className="btnPrimary">Withdraw</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mb">
                <div className="dashboardRankCard">
                  <h6 style={{ color: "#3eab95" }}>
                    {dashboardData?.profile?.[0]?.my_rank ?? "0"}
                  </h6>

                  <ProgressBars directs={directs} ranks={ranks} team={team} />
                </div>
              </Col>
              <Col md="4" className="mb">
                <div className="dashboardRankCard">
                  <h6 style={{ color: "#3eab95" }}>Booster</h6>

                  <BoosterProgressBars
                    boosterDirect={boosterDirect}
                    boosterBusiness={boosterBusiness}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <section className="mt-3">
          <Row>
            <Col lg="4" className="mb-2">
              <div className="wallets">
                <div className="walletsIconDiv">
                  <div className="walletsImg">
                    {" "}
                    <img src={Team} alt="" />
                    <h1>Team</h1>
                  </div>
                </div>
                <div className="walletsData">
                  <p>Directs</p>
                  <p>
                    {dashboardData?.teams?.active_directs}/
                    {dashboardData?.teams?.total_directs}
                  </p>
                </div>

                <div className="walletsData">
                  <p>Team</p>
                  <p>
                    {dashboardData?.teams?.active_gen}/
                    {dashboardData?.teams?.total_gen}
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              className="gap-2 d-flex flex-column justify-content-between"
            >
              <Row>
                {dashboardData?.incomes != null
                  ? dashboardData?.incomes?.map((x, i) => {
                      return (
                        <Col md="6" className="mb-2">
                          <div className="dashboardIncomeCard">
                            <div className="dashboardData">
                              <div>
                                <h5
                                  className="dashboardCardHeading"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {x?.name}
                                </h5>
                                <h1>
                                  {parseFloat(x?.value).toFixed(2) ?? "0"}
                                </h1>
                              </div>
                              <div>
                                <img
                                  src={x?.icons}
                                  style={{ height: 30, width: 30 }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  : myArray?.map((x, i) => {
                      return (
                        <Col md="6" className="mb-2">
                          <div className="dashboardIncomeCard">
                            <div>
                              <div>
                                <h5 className="dashboardCardHeading">
                                  ................
                                </h5>
                              </div>
                            </div>
                            <h1>0.00</h1>
                            <div></div>
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </Col>
          </Row>
        </section>

        <h1 className="textHeadingWithMargin">Telegram</h1>
        <div className="telegramBotDiv">
          <Row className="align-items-center">
            <Col md="6">
              <div id="telegramBotDivText">
                <i>
                  <FaTelegramPlane />
                </i>
                <div>
                  <h5>{Data.projectName} Notifier</h5>
                  <p>New partners and transactions notifications</p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <button
                onClick={() =>
                  window.open(dashboardData?.telegram_link, "_blank")
                }
                className="btnPrimary"
              >
                Connect
              </button>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
