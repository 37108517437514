const BUSD_ABI = require("../Contracts/BUSD_ABI.json");
const ContractABI = require("../Contracts/ContractABI.json");

const arr = {
  BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
  BUSD_ABI: BUSD_ABI,
  Contract: "0x514E970F74E4cDB56f5dAab14D66Dd1093AE39c7",
  ContractABI: ContractABI,
};

const ContractDetails = arr;
module.exports = ContractDetails;
