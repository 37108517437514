import React, { useState } from "react";
import { BiDollarCircle } from "react-icons/bi";
import "./UpgradeCard.css";
import axios from "axios";
import { ApiPaths } from "../../Config";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { Data } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import GetChainId from "../../Common/GetChainId";

const UpgradeCard = (props) => {
  const { BigInt } = window;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [retopupAmount, setRetopupAmount] = useState("");
  const [subsAmountError, setSubsAmountError] = useState("");
  const [subsPackageError, setSubsPackageError] = useState("");
  const [stakingPeriod, setStakingPeriod] = useState(1);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  function resetSubError() {
    setSubsPackageError("");
    setSubsAmountError("");
  }

  async function Topup(amount) {
    let increaseDone = await IncreaseAllowance(amount);
    if (increaseDone === true) {
      console.log({ amount });
      const newAmount = BigInt(amount * 1e18);
      setLoading(true);
      try {
        const { ethereum } = window;
        if (ethereum) {
          let chain = await GetChainId();
          if (chain) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const busdInstance = new ethers.Contract(
              ContractDetails.Contract,
              ContractDetails.ContractABI,
              signer
            );
            let inc = await busdInstance.stake(
              newAmount,
              "0x50966810A133cDf7083BDE254954A8D61041d09B",
              stakingPeriod * 365
            );
            const transactionData = await inc.wait();
            console.log("Transaction Data ", transactionData);
            const hash = transactionData.transactionHash;
            console.log("Transaction Hash: ", hash);
            return hash;
          }
        } else {
          setLoading(false);
          return null;
        }
      } catch (error) {
        toastFailed("Transaction Failed");
        console.log("Error during Topup: ", error);
        setLoading(false);
        return null;
      }
    } else {
      setLoading(false);
      return null;
    }
  }

  async function RetopupPackage() {
    console.log("package", parseInt(props?.data?.pin_rate));
    resetSubError();
    if (stakingPeriod === "0") {
      setSubsPackageError("Please select package");
      return;
    }
    console.log("stakingPeriod", stakingPeriod);
    // if (
    //   parseInt(retopupAmount) < parseInt(props?.data?.pin_rate) ||
    //   parseInt(retopupAmount) > parseInt(props?.data?.max_amount)
    // ) {
    //   setSubsAmountError(
    //     `Amount should be between ${props?.data?.pin_rate} to ${props?.data?.max_amount}`
    //   );
    //   return;
    // }
    setRetopupAmount(parseInt(props?.data?.pin_rate));
    if (stakingPeriod !== "0") {
      setLoading(true);

      const isEligible = await checkEligible(retopupAmount);
      console.log("isEligible: ", isEligible);
      if (isEligible === true) {
        let txHash = await Topup(retopupAmount);
        console.log("Hash: ", txHash); // Log to ensure txHash is received correctly
        if (txHash) {
          // Check if txHash is valid
          console.log("Sending axios request with txHash: ", txHash);
          try {
            const response = await axios({
              method: "post",
              url: ApiPaths.retopup,
              data: {
                amount: retopupAmount,
                tx_hash: txHash,
                selected_pin: props?.data?.pin_type,
                staking_period: stakingPeriod,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
              },
            });
            console.log("axios response: ", response);
            if (response?.data?.res === "error") {
              if (response?.data?.tokenStatus === "false") {
                toastFailed("Token Expired Please Login");
              } else {
                toastFailed(response?.data?.message);
                setShowOtp(false);
              }
            } else {
              toastSuccess(response?.data?.message);
              setShowOtp(false);
            }
          } catch (error) {
            console.log("axios error: ", error);
          } finally {
            setLoading(false);
          }
        } else {
          toastFailed("Failed to get transaction hash.");
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }

  async function checkEligible(amount) {
    try {
      const response = await axios.post(
        ApiPaths.retopupEligiblity,
        {
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      console.log("response", response);
      if (response?.data?.res === "success") {
        return true;
      } else {
        toastFailed(response?.data?.message);
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async function IncreaseAllowance(amount) {
    console.log({ amount });
    const newAmount = BigInt(amount * 1e18);
    setLoading(true);
    try {
      const { ethereum } = window;
      if (ethereum) {
        let chain = await GetChainId();
        if (chain) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const busdInstance = new ethers.Contract(
            ContractDetails.BUSD,
            ContractDetails.BUSD_ABI,
            signer
          );
          let inc = await busdInstance.increaseAllowance(
            ContractDetails.Contract,
            newAmount
          );
          await inc.wait();
          return true;
        }
        setLoading(false);
        return false;
      } else {
        setLoading(false);
        console.log("Ethereum object not found");
        return false;
      }
    } catch (error) {
      toastFailed("Transaction Failed");
      console.log("Error during IncreaseAllowance: ", error);
      setLoading(false);
      return false;
    }
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="upgradesCard" draggable={true}>
        <h1>{props?.data?.pin_type}</h1>
        <button
          className="simplePrimaryButton"
          onClick={RetopupPackage} // Ensure this calls RetopupPackage
        >
          Buy
        </button>
      </div>
      {showOtp ? (
        <div className="otpSection">
          <div className="addfundDiv inputPrimary">
            <h1>{props?.data?.rank}</h1>
            <label htmlFor="Amount">Amount</label>
            <div className="inputDivIcon">
              <input
                min={1}
                required
                type="text"
                name=""
                id=""
                placeholder="Enter Amount"
                value={retopupAmount}
                onChange={(e) => setRetopupAmount(e.target.value)}
              />
              <i>
                <BiDollarCircle />
              </i>
            </div>
            <p className="errorMsg">{subsAmountError}</p>
            <select
              value={stakingPeriod}
              onChange={(e) => setStakingPeriod(e.target.value)}
            >
              <option value="0">Staking Period</option>
              <option value="2">2 Years</option>
              <option value="3">3 Years</option>
              <option value="5">5 Years</option>
            </select>
            <p className="errorMsg">{subsPackageError}</p>
            <p
              className=""
              style={{
                color: Data.colorPrimary,
                fontSize: "14px",
                margin: "5px 0",
              }}
            >
              Enter amount between ${props?.data?.pin_rate} to $
              {props?.data?.max_amount}
            </p>
            {loading ? (
              <div className="otpLoading"></div>
            ) : (
              <div className="d-flex" style={{ columnGap: "15px" }}>
                <button
                  className="btnSecondary mt-3 "
                  onClick={() => setShowOtp(false)}
                >
                  Cancel
                </button>
                <button className="btnPrimary mt-3" onClick={RetopupPackage}>
                  Proceed
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UpgradeCard;
