import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import "./MyChart.css";
import { Data } from "../../Common/Data";
const MyChart = (props) => {
  let myData = props?.data;
  // console.log("//", myData?.total_earning);
  let inv;
  let remaining = 0.0;
  let ern = myData?.total_earning;
  let cap = 0;
  let pkgInfo = myData?.pkg_info;
  for (let i = 0; i < pkgInfo?.length; i++) {
    cap += parseFloat(pkgInfo[i]?.capping);
  }

  remaining = parseFloat(cap) - parseFloat(ern);

  const data = [
    {
      title: "Investment",
      value: parseFloat(remaining ?? 0.00000000001),
      color: "grey",
    },
    {
      title: "Earning",
      value: parseFloat(ern ?? 0.00000000001),
      color: Data.colorPrimary,
    },
  ];

  return (
    <div className="capping">
      <div className="cappingGraphDiv">
        <div style={{ height: "120px" }}>
          <PieChart
            animate={true}
            animationDuration={2000}
            data={data}
            lineWidth={30}
            paddingAngle={2}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fill: "#fff",
              fontSize: "5px",
              fontFamily: "sans-serif",
              fontSize: "8px",
              fontWeight: "bold",
              borderRadius: "50%",
              background: "red !important",
            }}
          />
        </div>
        <div className="cappingAbout ">
          <div>
            <span style={{ background: Data.colorPrimary }}></span>
            <p>Earning</p>
          </div>
          <div>
            <span style={{ background: "grey" }}></span>
            <p>Remaining</p>
          </div>
        </div>
      </div>

      <div className="cappingDetails">
        <div>
          <h1>${cap}</h1>
          <p>Capping</p>
        </div>
        {/* <hr /> */}
        <div>
          <h1>${parseFloat(ern).toFixed(2)}</h1>
          <p>Earning</p>
        </div>
        {/* <hr /> */}
        <div>
          <h1>${parseFloat(remaining).toFixed(2)}</h1>
          <p>Remaining</p>
        </div>
      </div>
    </div>
  );
};

export default MyChart;
// animationDuration: number;
// animationEasing: string;
// center: [number, number];
// data: Data < BaseDataEntry >;
// labelPosition: number;
// lengthAngle: number;
// lineWidth: number;
// paddingAngle: number;
// radius: number;
// startAngle: number;
// viewBoxSize: [number, number];
